<template>
  <div class="cardList overview" data-testid="dashboard-card-list">
    <div class="header">
      <div class="header-1">
        {{ $t('dashboard_section_cards') }}
      </div>
      <div class="go-to" @click="viewAll">
        {{ $t('dashboard_section_viewAll') }}
        <span class="icon-arrow-right" />
      </div>
    </div>
    <div class="cardList__list" :class="{'has-cards': cards.length}">
      <ul>
        <li class="new-card" @click="create">
          <div class="new-card__inside">
            <div>
              <i class="el-icon-circle-plus-outline" />
              <h5>{{ $t('card_add_label') }}</h5>
            </div>
          </div>
        </li>
        <li v-for="card in cards" :key="card.id">
          <Card :card="card"
                :show-logo="false"
                class="card-detail" 
                :show-activation-switch="true"
                :show-mini-details="true"
                @changeDrawer="updateDrawer($event)" />
        </li>
      </ul>
    </div>

    <Drawer key="card-create-drawer" class="default-drawer" :components="cardCreateRoutes" :show.sync="cardCreateDialog" v-if="cardCreateDialog" />
    <Drawer key="card-activate-drawer" class="default-drawer" :components="cardActivateRoutes" :show.sync="cardActviateDialog" v-if="cardActviateDialog" />
    <Drawer key="card-manage-drawer" class="default-drawer" :components="cardManageRoutes" :show.sync="cardMangeDialog" v-if="cardMangeDialog" />
  </div>
</template>
<script>
import Card from '@m/card/components/Card';
import { mapMutations } from 'vuex';
import Drawer from '@/components/Drawer.vue';
export default {
  props: {
    // eslint-disable-next-line
    cards: Array,
  },
  components: {
    Card,
    Drawer
  },
  data() {
    return {
      cardActviateDialog: false,
      cardCreateDialog: false,
      cardMangeDialog: false,
      cardActivateRoutes: [
        {
          default: true,
          name: 'ActivateCardForm',
          component: () => import('@m/card/views/ActivateCardForm.vue')
        },
        {
          name: 'CardActivated',
          component: () => import('@m/card/views/CardActivated.vue'),
          methods: {
            refresh : () => {
              this.getData();
            }
          },
        },
        {
          name: 'CardSpendingForm',
          component: () => import('@m/card/views/CardSpendingForm.vue')
        },
        {
          name: 'ReplaceCardReason',
          component: () => import('@m/card/views/ReplaceCardReason.vue')
        },
        {
          name: 'ReplaceCardDetails',
          component: () => import('@m/card/views/ReplaceCardDetails.vue')
        },
        {
          name: 'ReplaceCardAddress',
          component: () => import('@m/card/views/ReplaceCardAddress.vue')
        }
      ],
      cardCreateRoutes: [
        {
          default: true,
          name: 'CardTypeForm',
          component: () => import('@m/card/views/CardTypeForm.vue')
        },
        {
          name: 'CardSpendingForm',
          component: () => import('@m/card/views/CardSpendingForm.vue')
        },
        {
          name: 'CardBillingAddressForm',
          component: () => import('@m/card/views/CardBillingAddressForm.vue')
        },
        {
          name: 'CardShippingAddressForm',
          component: () => import('@m/card/views/CardShippingAddressForm.vue')
        },
        {
          name: 'CreatedSuccess',
          component: () => import('@m/card/views/CreatedSuccess.vue'),
          methods: {
            afterCreated : () => {
              this.getData();
            },
            goToDetailsScreen: ($event) => {
              this.cardCreateDialog = false;
              this.updateDrawer($event);
            }
          },
        }
      ],
      cardManageRoutes: [
        {
          default: true,
          name: 'CardManagement',
          component: () => import('@m/card/views/CardManagement.vue'),
          methods: {
            refresh : () => {
              this.getData();
            }
          },
        },
        {
          name: 'CardDetails',
          component: () => import('@m/card/views/CardDetails.vue')
        },
        {
          name: 'CardSpendingForm',
          component: () => import('@m/card/views/CardSpendingForm.vue'),
          methods: {
            refresh : () => {
              this.getData();
            }
          },
        },
        {
          name: 'AtmInfo',
          component: () => import('@m/card/views/ATMInfo.vue'),
        },
        {
          name: 'SetATMPin',
          component: () => import('@m/card/views/SetATMPin.vue'),
          methods: {
            refresh : () => {
              this.page = 1;
              this.getData();
            }
          },
        },
        {
          name: 'findATM',
          component: () => import('@m/card/views/FindATM.vue'),
          methods: {
            refresh : () => {
              this.page = 1;
              this.getData();
            }
          },
        },        
        {
          name: 'ReplaceCardReason',
          component: () => import('@m/card/views/ReplaceCardReason.vue')
        },
        {
          name: 'ReplaceCardDetails',
          component: () => import('@m/card/views/ReplaceCardDetails.vue')
        },
        {
          name: 'ReplaceCardAddress',
          component: () => import('@m/card/views/ReplaceCardAddress.vue')
        }
      ]
    }
  },
  methods: {
    ...mapMutations('card', [
      'updateSelectedCard',
      'updateReplaceCardDate',
      'updateCreateCardData'
    ]),
    viewAll() {
      window.analytics && window.analytics.track('homeViewAllCards');
      this.$router.push('/card/list');
    },
    updateDrawer(drawerRoute) {
      if (drawerRoute == 'activate') {
        this.cardActviateDialog = true;
      } else if (drawerRoute == 'manage') {
        this.cardMangeDialog = true;
      }
    },
    create() {
      this.updateCreateCardData(null);
      this.updateReplaceCardDate(null);
      this.updateSelectedCard(null);
      this.cardCreateDialog = true;
    },
    getData() {
      this.$emit('update');
    }
  },
};
</script>
<style lang="scss">
  .overview .cardList__list {
  ul {
    flex-wrap: nowrap;
    overflow-x: auto;

    li {
      min-width: 334px;

      &:nth-child(3n) {
        margin-right: 24px;
      }
    }
  }
}

</style>