<template>
  <div class="widgetContainer widgetContainer--new dashboardHome full-width">
    <div class="widgetContainer__body">
      <div class="header" v-if="!getIsMobileLayout">
        <SkeletonText v-if="loading" />
        <div class="label" v-if="!loading">
          <p v-if="selectedAccount.type !== 'personalChecking'" class="header-1 account_name">
            {{ getSelectedBusinessName }}
          </p>
          <p v-else class="header-1 account_name">
            {{ getPersonDetails.firstName + ' ' +getPersonDetails.lastName }}
          </p>
        </div>
        <div v-if="selectedAccount.type !== 'personalChecking'" class="go-to" @click="goToBusinessDetails()">
          {{ $t('dashboard_section_viewDetails') }}
          <span class="icon-arrow-right" />
        </div>
      </div>

      <div class="dashboardHome__account-view">
        <SkeletonSimpleCard :count="3" v-if="loading" />
        <div v-if="!loading" class="dashboardHome__funds" data-testid="dashboard-top-card">
          <div class="dashboardHome__funds__receivables" data-testid="current-account">
            <div class="header">
              <p class="label">
                {{ selectedAccount.label }}
              </p>
              <p class="view" @click="goToAccountDetails">
                View Details
              </p>
            </div>
            <p class="amount">
              {{ selectedAccount.availableBalance | currency }}
            </p>
            <p class="account-number">
              <span>
                {{ $t('dashboard_accNo_title') }} {{ selectedAccount.accountNumber }}
              </span>
              <i class="icon-copy" @click="copyAction(selectedAccount.accountNumber, 'Account number')" />
            </p>
          </div>
                      
          <div class="dashboardHome__funds__transfer">
            <h1 class="header-1 desktop-hide">
              {{ $t('dashboard_section_movemoney') }}
            </h1>
            <div
              @click="goToFund"
              v-if="isTransferMoneyEnabled">
              <div class="details">
                <p class="label">
                  {{ $t('transfer_money') }}
                </p>
                <p class="value">
                  {{ $t('dashboard_row_fund_desc', {value1: selectedAccount.label}) }}
                </p>
              </div>
              <div class="action">
                <span class="icon-Arrow-small-right" />
              </div>
            </div>

            <div
              @click="pay"
              v-if="isSendMoneyEnabled">
              <div class="details">
                <p class="label">
                  {{ $t('send_money') }}
                </p>
                <p class="value">
                  {{ $t('dashboard_row_pay_desc', {value1: selectedAccount.label}) }}
                </p>
              </div>
              <div class="action">
                <span class="icon-Arrow-small-right" />
              </div>
            </div>
          </div>
        </div>

        <contacts-grid @onPay="onPay" :initia-call="false" :pagination="false" layout="grid" />

        <SkeletonList title="Transactions" :count="3" v-if="loading" />
        <RecentTransactions v-if="!loading" />
        <div v-if="!loading && getRecentTransactions.length === 0">
          <div class="noData">
            <h1 class="header-1">
              {{ $t('dashboard_section_transactions') }}
            </h1>
            <p>
              {{ $t('noSearchTransactions') }}
            </p>
          </div>
        </div>

        <SkeletonCreditCard title="Cards" :count="3" v-if="loading" />
        <Cards v-if="!loading" :cards="getCards.data" @update="listAllCardByAccount({accountId:getSelectedAccount.id})" />
      </div>
    </div>

    <Drawer class="default-drawer" :components="accountDetailsRoutes" :show.sync="accountDetailsDialog" v-if="accountDetailsDialog" />
    <Drawer class="default-drawer" :components="businessDetailsRoutes" :show.sync="showBusinessDetails" v-if="showBusinessDetails" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { getUIConfig } from '@/utils/env';
import Cards from './cards/Cards';
import RecentTransactions from '@m/transaction/views/TransactionRecent.vue';
import Drawer from '@/components/Drawer.vue';
import SkeletonText from '@/components/Skeleton/SkeletonText.vue';
import SkeletonSimpleCard from '@/components/Skeleton/SkeletonSimpleCard.vue';
import SkeletonList from '@/components/Skeleton/SkeletonList.vue';
import SkeletonCreditCard from '@/components/Skeleton/SkeletonCreditCard.vue';
import ContactsGrid from '@m/contact/views/Contacts.vue';

export default {
  name: 'DashboardHome',
  components: {
    Cards,
    RecentTransactions,
    Drawer,
    SkeletonText,
    SkeletonSimpleCard,
    SkeletonList,
    SkeletonCreditCard,
    ContactsGrid
  },
  data() {
    return {
      search: null,
      accountDetailsDialog: false,
      showBusinessDetails: false,
      loading: false,
      isTransferMoneyEnabled: getUIConfig('isTransferMoneyEnabled'),
      isSendMoneyEnabled: getUIConfig('isSendMoneyEnabled'),
      accountDetailsRoutes: [
        {
          name: 'details',
          default: true,
          component: () => import('@m/account/views/AccountDetails.vue')
        },
        {
          name: 'statement',
          component: () => import('@m/account/views/Statements.vue')
        }
      ],
      businessDetailsRoutes: [
        {
          name: 'BusinessDetails',
          default: true,
          component: () => import('@m/business/views/BusinessDetails.vue')
        },
        {
          name: 'EditBusinessAddress',
          component: () => import('@m/business/views/EditBusinessAddress.vue')
        }
      ],
    };
  },
  computed: {
    ...mapGetters(['getIsMobileLayout']),
    ...mapGetters('account', [
      'getAllAccount',
      'getSelectedAccount',
      'getGroupedAccounts'
    ]),
    ...mapGetters('contact', ['getContacts']),
    ...mapGetters('transaction', ['getRecentTransactions', 'getFilters']),
    ...mapGetters('card', ['getCards']),
    ...mapGetters('kyb', ['getSelectedBusiness']),
    ...mapGetters('user',['getPersonDetails']),
    ...mapGetters('account', ['isBusinessAccountType']),
    selectedAccount() {
      return this.getSelectedAccount;
    },
    getSelectedBusinessName() {
      if(this.getSelectedBusiness != null) {
        return this.getSelectedBusiness.legalName;
      }
      return ''
    }
  },
  created() {
    /* TODO: Need to add Transaction api */
    if (this.getSelectedAccount === null) {
      this.goToWelcomeScreen();
      return;
    }
    const accountId = this.getSelectedAccount.id;
    // let loader = false;
    this.loading = false;
    const promises = [];

    if (this.getCards.data.length === 0) {
      this.loading = true;
    }

    if (this.getRecentTransactions.length === 0) {
      this.loading = true;
    }
    if (this.loading) {
      this.loading = true;
    }

    promises.push(this.listAllCardByAccount({accountId}));
    promises.push(
      this.fetchRecentTransactions({
        accountId
      })
    );
    promises.push(this.listAllContacts({
      accountId,
      limit: 25
    }))

    Promise.all(promises)
      .then(() => {
      })
      .catch((e) => {
        this.apiErrorMessage(e);
      })
      .finally(() => {
        this.loading = false;
      });

    this.getAccount(accountId).then((account) => {
      const accounts = this.getAllAccount.map((a) => {
        if (a.id === account.id) {
          account.availableBalance =
            account.availableBalance.length === 0
              ? '0.00'
              : account.availableBalance;
          return account;
        }
        return a;
      });
      this.setAccounts(accounts);

      const accountGroups = this.getGroupedAccounts;
      if (accountGroups) {
        const accounts = accountGroups.map((a) => {
          if (a.id === account.id) {
            account.availableBalance =
              account.availableBalance.length === 0
                ? '0.00'
                : account.availableBalance;
            return { ...account };
          }
          return a;
        });

        this.setGroupedAccounts(accounts);
      }
    });
  },
  methods: {
    ...mapActions('transaction', ['fetchRecentTransactions']),
    ...mapActions('card', ['listAllCardByAccount']),
    ...mapActions('account', ['getAccount']),
    ...mapActions('contact', ['listAllContacts']),
    ...mapMutations('account', ['setAccounts', 'setGroupedAccounts']),
    goToBusinessDetails() {
      window.analytics && window.analytics.track('homeEntityDetails');
      this.showBusinessDetails = true
    },
    goToAccount() {
      window.analytics && window.analytics.track('homeViewAllAccounts');
      this.$router.push('/account/list');
    },
    pay() {
      window.analytics && window.analytics.track('homePay');
      this.$router.push('/contact/list');
    },
    goToAccountDetails() {
      window.analytics && window.analytics.track('homeAccountDetails');
      this.accountDetailsDialog = true;
      //this.$router.push('/account/details');
    },
    goToFund() {
      window.analytics && window.analytics.track('homeFund');
      this.$router.push('/fund');
    },
    onPay() {
      const accountId = this.getSelectedAccount.id;
      this.fetchRecentTransactions({
        accountId
      });
    },
    onAccountChange(a) {
      const accountId = a.id;
      this.loading = true;
      const promises = [];
      promises.push(this.listAllCardByAccount({accountId}));
      promises.push(
        this.fetchRecentTransactions({
          accountId
        })
      );

      Promise.all(promises)
        .then(() => {
        })
        .catch((e) => {
          this.apiErrorMessage(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    copyAction(textToBeCopied, type) {
      const that = this;
      navigator.clipboard.writeText(textToBeCopied).then(
        function () {
          that.$notify({
            title: 'Success',
            message: `${type} copied`,
            type: 'success'
          });
        },
        function () {
          that.$apiErrorMessage(`Unable to copy ${type}`);
        }
      );
    },
  },
  watch: {
    getSelectedAccount(newVal, oldVal) {
      if(newVal && oldVal && newVal.id !== oldVal.id) {
        this.onAccountChange(newVal)
      }
    }
  }
};
</script>

<style lang="scss">
  .dashboardHome {
    overflow: hidden;

    @media screen and (max-width: 992px) {
      overflow: hidden;

      .widgetContainer__body {
        overflow: hidden;
        overflow-y: auto;
      }
    }

    .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;
      padding-bottom: 0;

      .header-1 {
        padding-bottom: 0;
      }

      .go-to {
        color: var(--grey-1);
        cursor: pointer;
        display: flex;
        font-size: 16px;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 768px) {
          font-size: 14px;
        }

        span {
          padding-left: 10px;
          font-size: 12px;
        }
      }
    }

    .skeleton-account-view {
      padding-bottom: 32px;
    }

    .card__info {
      padding: 0 16px 18px 16px;
    }

    &__funds {
      display: flex;
      padding-bottom: 32px;

      @media screen and (max-width: 992px) {
        flex-direction: column;
      }

      > div {
        width: 50%;

        @media screen and (max-width: 992px) {
          width: 100%;
        }
      }

      &__receivables {
        background: var(--branding-primary);
        border-radius: var(--radius);
        color: #fff;
        margin-right: 24px;
        padding: 24px;
        position: relative;

        @media screen and (max-width: 768px) {
          padding: 15px 15px 15px 22px;
          margin: 0;
        }

        .header {
          margin-bottom: 12px;
          font-weight: 500;

          @media screen and (max-width: 768px) {
            margin-bottom: 8px;
          }

          .label {
            font-size: 20px;
            line-height: 24px;

            @media screen and (max-width: 768px) {
              font-size: 16px;
            }
          }

          .view {
            text-decoration: underline;
            cursor: pointer;
            font-size: 14px;

            @media screen and (max-width: 768px) {
              position: absolute;
              bottom: 16px;
              right: 16px;
              font-size: 14px;
            }
          }
        }

        .amount {
          font-size: 30px;
          font-weight: 600;
          line-height: 36px;
          padding-bottom: 34px;

          @media screen and (max-width: 768px) {
            position: absolute;
            top: 16px;
            right: 16px;
            font-size: 14px;
            padding: 0;
            line-height: 18px;
          }
        }

        .account-number {
          font-size: 14px;

          @media screen and (max-width: 768px) {
            font-size: 12px;
          }

          .icon-copy {
            cursor: pointer;
            opacity: .5;
            margin-left: 21px;
            transition: .3s;

            &:hover {
              opacity: 1;
            }
          }
        }
      }

      &__transfer {
        @media screen and (max-width: 992px) {
          margin-top: 24px;
        }

        .header-2 {
          display: block;
        }

        > div {
          background-color: #fff;
          border-radius: var(--radius);
          cursor: pointer;
          padding: 16px 24px 16px 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          @media screen and (max-width: 768px) {
            padding-right: 18px;
          }

          &:first-of-type {
            margin-bottom: 16px;

            @media screen and (max-width: 768px) {
              margin-bottom: 12px;
            }
          }

          .label {
            color: var(--branding);
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            padding-bottom: 12px;

            @media screen and (max-width: 768px) {
              padding-bottom: 6px;
            }
          }

          .value {
            color: var(--grey-1);
            font-size: 14px;
            line-height: 16px;

            @media screen and (max-width: 768px) {
              font-size: 12px;
            }
          }

          .icon-Arrow-small-right {
            @media screen and (max-width: 992px) {
              font-size: 12px;
            }
          }
        }
      }

    }

    &__spend-account {
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 17px;

        .label {
          font-size: 18px;
          line-height: 120%;
          color: var(--branding);
          display: flex;
          align-items: center;
          cursor: pointer;

        }

        .go-to {
          color: var(--grey-1);
          cursor: pointer;
          display: flex;
          font-size: 14px;
          align-items: center;
          justify-content: center;

          span {
            padding-left: 10px;
          }
        }
      }
    }

    .move-money-header {
      line-height: 22px;
    }

    .listWithAmountNew ul {
      padding: 0px;

      li {
        padding-left: 16px;
        padding-right: 16px;
      }
    }

    .transactionRecent__transactions.listWithAmountNew ul li:hover {
      background-color: var(--light-grey-2);

      .details {
        .label {
          color: black;
        }

        .value {
          color: rgba(60, 60, 67, 0.6);
        }
      }
    }

    .move-money-header {
      line-height: 22px;
    }

    .transactionRecent__transactions .header .label {
      font-size: 24px;
      font-weight: 600;
    }

    .cards .header {
      padding-top: 16px;

      .label {
        font-size: 24px;
        font-weight: 600;
      }


    }

    .transactionsLoading {
      background: white;
      padding: 10px 16px;
      margin-bottom: 30px;
      border-radius: var(--radius);
    }

    .cardList {
      &__list {
        li {
          @media (max-width: 768px) {
            margin-right: 16px;
          }
        }
      }

      .widgetContainer__body {
        @media (max-width: 768px) {
          padding: 40px 17px 0px 24px;
        }
      }
    }
  }
</style>
